import {
    createRouter,
    createWebHashHistory
} from 'vue-router'
// import Utils from "@/utils/utils";
const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {

        path: '/homeMo',
        name: 'homeMo',
        redirect: '/mo',
        component: () => import('@/views/homePageSingle'),
        children: [
            {
                path: '/mo',
                component: () => import('@/components/order/memberOrder')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/loginPage')
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('@/components/commonPage/resetPassword'),
    },
    {
        path: '/editAdmin',
        name: 'editAdmin',
        component: () => import('@/components/commonPage/editAdmin'),
    },
    {
        path: '/home',
        name: 'home',
        redirect: '/dashboard',
        component: () => import('@/views/homePage'),
        children: [{
            path: '/index',
            name: 'index',
            component: () => import('@/views/homeIndexPage'),
            children: [{
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/components/dashboardView'),
            },
                {
                    path: '/member',
                    name: 'memberInfo',
                    component: () => import('@/components/member/memberInfo'),
                    redirect: '/member/memberList',
                    children: [{
                        path: '/member/memberList',
                        name: 'memberList',
                        component: () => import('@/components/member/memberList')
                    },
                        {
                            path: '/member/memberDetail',
                            name: 'memberDetail',
                            component: () => import('@/components/member/memberDetail')
                        },
                        {
                            path: '/member/detailPage',
                            name: 'detailPage',
                            component: () => import('@/components/member/memberDetail/detailPage')

                        },

                    ]
                },
                {
                    path: '/postpone',
                    name: 'postpone',
                    component: () => import('@/components/postpone/postpone'),
                    children: [{
                        path: '/postpone/postponeMemberView',
                        name: 'postponeMemberView',
                        component: () => import('@/components/postpone/postponeMemberView')
                    },
                        {
                            path: '/postpone/postponePrivateView',
                            name: 'postponePrivateView',
                            component: () => import('@/components/postpone/postponePrivateView')
                        },
                        {
                            path: '/postpone/postponeMemberListView',
                            name: 'postponeMemberListView',
                            component: () => import('@/components/postpone/postponeMemberListView')

                        },
                        {
                            path: '/postpone/postponePrivateListView',
                            name: 'postponePrivateListView',
                            component: () => import('@/components/postpone/postponePrivateListView')
                        },
                    ]
                },
                {
                    path: '/cashierDesk',
                    name: 'cashierDesk',
                    component: () => import('@/components/cashierDesk/financePage'),
                    redirect: '/cashierDesk/cashierDesk',
                    children: [{
                        path: '/cashierDesk/cashierDesk',
                        name: 'cashierDesk',
                        component: () => import('@/components/cashierDesk/cashierDesk')
                    },
                        {
                            path: '/cashierDesk/cashierDestSubPages/contractNewView',
                            name: 'contractNewView',
                            component: () => import(
                                '@/components/cashierDesk/cashierDestSubPages/contractNewView')
                        },
                        {
                            path: '/cashierDesk/cashierDestSubPages/contractPrivateUpgradeView',
                            name: 'contractPrivateUpgradeView',
                            component: () => import(
                                '@/components/cashierDesk/cashierDestSubPages/contractPrivateUpgradeView'
                                )
                        },
                        {
                            path: '/cashierDesk/cashierDestSubPages/contractMemberUpgradeView',
                            name: 'contractMemberUpgradeView',
                            component: () => import(
                                '@/components/cashierDesk/cashierDestSubPages/contractMemberUpgradeView'
                                )
                        },
                        {
                            path: '/cashierDesk/cashierDestSubPages/contractMemberRenewView',
                            name: 'contractMemberRenewView',
                            component: () => import(
                                '@/components/cashierDesk/cashierDestSubPages/contractMemberRenewView'
                                )
                        },
                        {
                            path: '/cashierDesk/statementView',
                            name: 'statementView',
                            component: () => import('@/components/cashierDesk/statementView')
                        },
                        {
                            path: '/cashierDesk/performanceView',
                            name: 'performanceView',
                            component: () => import('@/components/cashierDesk/performanceView')
                        },
                        {
                            path: '/cashierDesk/historyPerformanceView',
                            name: 'historyPerformanceView',
                            component: () => import(
                                '@/components/cashierDesk/historyPerformanceView')
                        },
                        {
                            path: '/cashierDesk/searchPerformanceView',
                            name: 'searchPerformanceView',
                            component: () => import(
                                '@/components/cashierDesk/searchPerformanceView')
                        },

                    ],
                },
                {
                    path: '/entrance',
                    name: 'entranceView',
                    component: () => import('@/components/entrance/entranceView'),
                    redirect: '/entrance/entranceRecord',
                    children: [{
                        path: '/entrance/entranceRecord',
                        name: 'entranceRecord',
                        component: () => import('@/components/entrance/entranceRecord')
                    },
                        {
                            path: '/entrance/chargeOffRecord',
                            name: 'chargeOffRecord',
                            component: () => import('@/components/entrance/chargeOffRecord')
                        },

                    ]
                },
                {
                    path: '/tripList',
                    name: 'tripListView',
                    component: () => import('@/components/tripList/tripListView'),
                    redirect: '/tripList/tripListView',
                    children: [{
                        path: '/tripList/tripListView',
                        name: 'tripListView',
                        component: () => import('@/components/tripList/tripListView')
                    },]
                },
                {
                    path: '/s_c_card',
                    name: 's_c_card',
                    component: () => import('@/components/stopAndChangeCard/stopAndChangeCardView'),
                },
                {
                    path: '/s_c_card/stopChangeDetailPage',
                    name: 'stopChangeDetailPage',
                    component: () => import('@/components/stopAndChangeCard/stopChangeDetailPage'),
                },
                {
                    path: '/coach',
                    name: 'coach',
                    component: () => import('@/components/coach/coachView'),
                    redirect: '/coach/coachList',
                    children: [{
                        path: '/coach/coachList',
                        name: 'coachList',
                        component: () => import('@/components/coach/coachList')
                    }]
                },
                {
                    path: '/order',
                    name: 'order',
                    component: () => import('@/components/order/orderInfo'),
                    redirect: '/coachOrder',
                    children: [

                        {
                            path: '/coachOrder',
                            name: 'coachOrder',
                            component: () => import('@/components/order/coachOrder')
                        },
                        {
                            path: '/groupOrder',
                            name: 'groupOrder',
                            component: () => import('@/components/order/groupOrder')
                        },
                        {
                            path: '/memberOrder',
                            name: 'memberOrder',
                            component: () => import('@/components/order/memberOrder')
                        },
                        {
                            path: '/packageOrder',
                            name: 'packageOrder',
                            component: () => import('@/components/order/packageOrder')
                        },
                        {
                            path: '/trainOrder',
                            name: 'trainOrder',
                            component: () => import('@/components/order/trainOrder')
                        },
                        {
                            path: '/stopChangeOrder',
                            name: 'stopChangeOrder',
                            component: () => import('@/components/order/stopChangeOrder')
                        },
                        {
                            path: '/orderDetail',
                            name: 'orderDetail',
                            component: () => import('@/components/order/orderDetail')
                        },
                    ]
                },
                {
                    path: '/coachDelegateAppointment',
                    name: 'coachDelegateAppointment',
                    component: () => import('@/components/clazz/coachDelegateAppointment')
                },
                {
                    path: '/clazz',
                    name: 'clazz',
                    component: () => import('@/components/clazz/clazzInfo'),
                    redirect: '/clazzPerson',
                    children: [{
                        path: '/clazzPerson',
                        name: 'clazzPerson',
                        component: () => import('@/components/clazz/clazzPerson')
                    },
                        {
                            path: '/clazzGroup',
                            name: 'clazzGroup',
                            component: () => import('@/components/clazz/clazzGroup')
                        },
                        {
                            path: '/clazzSetting',
                            name: 'clazzSetting',
                            component: () => import('@/components/clazz/clazzSetting')
                        },
                        {
                            path: '/clazzDetail',
                            name: 'clazzDetail',
                            component: () => import('@/components/clazz/clazzDetail')
                        },
                        {
                            path: '/egSetting',
                            name: 'egSetting',
                            component: () => import('@/components/clazz/egSetting')
                        },
                        {
                            path: '/priceSystem',
                            name: 'priceSystem',
                            component: () => import('@/components/clazz/priceSystem')
                        },

                    ]
                },
                {
                    path: '/course',
                    name: 'course',
                    component: () => import('@/components/course/courseInfo'),
                    redirect: '/coursePerson',
                    children: [{
                        path: '/coursePerson',
                        name: 'coursePerson',
                        component: () => import('@/components/course/coursePerson')
                    },
                        {
                            path: '/courseGroup',
                            name: 'courseGroup',
                            component: () => import('@/components/course/courseGroup')
                        }
                    ]
                },
                {
                    path: '/card',
                    name: 'card',
                    component: () => import('@/components/card/cardView'),
                    redirect: '/card/timeCardList',
                    children: [{
                        path: '/card/timeCardList',
                        name: 'timeCardList',
                        component: () => import('@/components/card/timeCardList')
                    },
                        {
                            path: '/card/numberCardList',
                            name: 'numberCardList',
                            component: () => import('@/components/card/numberCardList')
                        },
                    ]
                },
                {
                    path: '/training',
                    name: 'training',
                    component: () => import('@/components/training/trainingView.vue'),
                    redirect: '/training/trainingView',
                    children: [{
                        path: '/training/userTrainingView',
                        name: 'userTrainingView',
                        component: () => import('@/components/training/userTrainingView')
                    },
                        {
                            path: '/training/createTrainingView',
                            name: 'createTrainingView',
                            component: () => import('@/components/training/createTrainingView')
                        },
                        {
                            path: '/training/trainingDetailView',
                            name: 'trainingDetailView',
                            component: () => import('@/components/training/trainingDetailView')
                        },
                    ]
                },
                {
                    path: '/entranceGuard/entranceGuardView',
                    name: 'entranceGuardView',
                    component: () => import('@/components/entranceGuard/entranceGuardView.vue')
                },
                {
                    path: '/entranceGuard/entranceGuardUsersView',
                    name: 'entranceGuardUsersView',
                    component: () => import('@/components/entranceGuard/entranceGuardUsersView.vue')
                },
                {
                    path: '/setting',
                    name: 'setting',
                    component: () => import('@/components/setting/settingView'),
                    redirect: '/setting/storeList',
                    children: [{
                        path: '/setting/storeList',
                        name: 'storeList',
                        component: () => import('@/components/setting/storeList'),
                    },
                        {
                            path: '/setting/storeSetting',
                            name: 'storeSetting',
                            component: () => import('@/components/setting/storeSetting')
                        }
                    ]
                },
            ]
        },],
    },

    {
        path: "/404",
        component: () => import('@/views/notFoundPage')
    },
    {
        path: "/:catchAll(.*)", // 不识别的path自动匹配404
        redirect: '/404',
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // Utils.myPrint('to-->');
    // Utils.myPrint(to)
    // Utils.myPrint('from-->');
    // Utils.myPrint(from)
    const token = localStorage.getItem('token')
    // Utils.myPrint(token)
    if (token) {
        next()
    } else {
        if (to.fullPath === '/login') {
            next()
        } else {
            next({
                path: "/login",
            });
        }
    }
})

export default router